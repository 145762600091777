<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="risList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search RIS number"
            color="green darken-2"
            class="mr-2 mb-2"
            dense
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
                color="blue darken-2"
                class="mr-2"
                dark
                outlined
                elevation="2"
                x-small
                @click="viewRIS(item)"
              >
                View
              </v-btn>
              <v-btn
                color="red darken-2"
                dark
                outlined
                elevation="2"
                x-small
                @click="printRIS(item)"
              >
                Print
              </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogRISType" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select RIS type:</v-card-title>
        <v-card-text>
          <v-row justify="center" class="mt-2">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="supply()" text
                >Supply</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="accounting()" text
                >Accounting</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogRISType = false"
                >Cancel</v-btn
              >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RISModal :risData="risData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RISModal: () => import("./ViewRIS.vue"),
  },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    risList: [],
    risData: [],
    headers: [
      {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'RISPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
    dialogRISType: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewRIS", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRIS");
  },
  methods: {
    viewRIS(item) {
      this.risData = item;
    },
    printRIS(item) {
      this.itemToPrint = item;
      this.dialogRISType = true;
    },
    supply(){
      this.link = this.apiUrl + "/ris/report/" + this.itemToPrint.RISID + "/1";
      window.open(this.link);
    },
    accounting(){
      this.link = this.apiUrl + "/ris/report/" + this.itemToPrint.RISID + "/2";
      window.open(this.link);
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/getRISListPerPA", "POST", data).then((res) => {
        this.loading = false;
        this.risList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
    },
  },
};
</script>