var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DarkWhite"},[_c('v-data-table',{staticClass:"elevation-1 pt-2",attrs:{"headers":_vm.headers,"items":_vm.risList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-2 mb-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search RIS number","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item.FundSource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.FundCode)+" - "+_vm._s(item.SpecificFundSourceDescription)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.totalAmount)))])]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.viewRIS(item)}}},[_vm._v(" View ")]),_c('v-btn',{attrs:{"color":"red darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.printRIS(item)}}},[_vm._v(" Print ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogRISType),callback:function ($$v) {_vm.dialogRISType=$$v},expression:"dialogRISType"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Please select RIS type:")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"6","xs":"12"}},[_c('v-icon',{attrs:{"dense":"","x-large":"","color":"green darken-2"}},[_vm._v("mdi-file-document-multiple")]),_c('br'),_c('v-btn',{attrs:{"color":"green darken-2","text":""},on:{"click":function($event){return _vm.supply()}}},[_vm._v("Supply")])],1),_c('v-col',{attrs:{"align":"center","cols":"6","xs":"12"}},[_c('v-icon',{attrs:{"dense":"","x-large":"","color":"green darken-2"}},[_vm._v("mdi-file-document-multiple-outline")]),_c('br'),_c('v-btn',{attrs:{"color":"green darken-2","text":""},on:{"click":function($event){return _vm.accounting()}}},[_vm._v("Accounting")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-2","text":""},on:{"click":function($event){_vm.dialogRISType = false}}},[_vm._v("Cancel")])],1)],1)],1),_c('RISModal',{attrs:{"risData":_vm.risData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }